<template>
	<div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		mounted: function() {
			var  _this = this
			if(this.$route.query&&this.$route.query.pathto){
				this.$router.push({ path: '/'+_this.$route.query.pathto, query: _this.$route.query})
			}else{
				this.$util.routerPath("/404")
			}
		},
		methods: {
			
		}
	}
</script>

<style>

</style>
